import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type SEOProps = {
  description?: string;
  meta?:
    | { name: string; content: any; property?: undefined }
    | { property: string; content: any; name?: undefined }[];
  title: string;
  siteTitleOverride?: string;
};

export const SEO: React.FC<SEOProps> = ({
  description = "",
  meta = [],
  title,
  siteTitleOverride,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang: "en-us",
      }}
      title={title}
      titleTemplate={`%s | ${
        siteTitleOverride ? siteTitleOverride : site.siteMetadata.title
      }`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
};
